const $ = require('jquery')
import consumer from "./consumer"

$(window).on('ajax:before', function () {
  global.sound?.stop()
})

$(window).on('load turbolinks:load', function () {
  const chanelIdentifier = global.AdminChannel?.identifier && JSON.parse(global.AdminChannel.identifier) || {}
  const { adminId } = document.querySelector('body')?.dataset || {}

  global.sound?.stop()

  if (+chanelIdentifier.admin_id !== +adminId) {
    global.sound = notifySoundLoop()

    global.AdminChannel = consumer.subscriptions.create({
      channel: "AdminChannel",
      admin_id: adminId
    }, {
      connected() {
        console.log(`Connected to admin ${adminId}`)
      },
      disconnected() {
        sound.stop()
      },
      received({ created_count, url, html, action }) {
        if (action !== 'created') return
        const $orders = $('#orders').filter('[data-filter="created"]')
        const $counterCreated = $('#counter-created')

        $orders.prepend($(html))
        $counterCreated.html(created_count)
        $counterCreated.attr('data-flashing', !!created_count)
        $counterCreated.attr('data-any', !!created_count)
        sound.start()

        flash({
          type: 'info',
          message: '"Переглянути?"',
          title: 'У вас нове замовлення!',
          options: {
            timeOut: 0,
            onclick: function() {
              sound.stop()
              window.location = url
            }
          },
        })
      }
    })
  }
})
