/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)

import './style'

import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(100)

Turbolinks.ProgressBar.prototype.refresh = function() {
	return requestAnimationFrame((t => () => {
    t.progressElement.dataset.value = parseInt(5 + 95 * t.value) + "%"
  })(this))
}

global.safeStartTurbolinksProgress = function () {
  if(!Turbolinks.supported) { return; }
  Turbolinks.controller.adapter.progressBar.setValue(0);
  Turbolinks.controller.adapter.progressBar.show();
  setTimeout(safeStopTurbolinksProgress, 20000)
}

global.safeStopTurbolinksProgress = function () {
  if(!Turbolinks.supported) { return; }
  Turbolinks.controller.adapter.progressBar.hide();
  Turbolinks.controller.adapter.progressBar.setValue(1);
}

global.$ = require('jquery')
global.jQuery = $

global.Rails = require('@rails/ujs')
Rails.start()

global.Activestorage = require('@rails/activestorage')
Activestorage.start()

import 'channels'
import 'jquery.maskedinput/src/jquery.maskedinput'

console.log('Application Loaded')
